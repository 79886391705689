<!--
	genuinePG frontend

	Page, 'home' page of App

  @file         Page_check.svelte
  @copyright    P&G
-->

<script>
  import { PAGE } from "./config";
  import {UploadIcon} from "svelte-feather-icons"
  // import {theme} from './stores'
  export let theme;
  import {
    URI_API,
    URI_IMG,
    toEncrypt,
    toDecrypt,
    initializeScanbot,
  } from "./minions";
  import { DeleteIcon } from "svelte-feather-icons";
  import { afterUpdate, createEventDispatcher } from "svelte";
  let dispatch = createEventDispatcher();

  import M_scanEAN from "./Modal_scanEAN.svelte";
  import M_badlistMatch from "./Modal_badlistMatch.svelte";
  import I_barcode from "./Icon_barcode.svelte";
  import I_batch from "./Icon_batch.svelte";

  import * as pako from "pako";
  import { scanbotSDK } from "./minions";
  let scanbotInitializationDone = false;
  let showLoader = false;

  // ============================= store variables =============================

  import {
    st_userRegistered,
    st_auth,
    st_email,
    st_instructions,
    st_location_latitude,
    st_location_longitude,
    st_location_accuracy,
    st_ean,
    st_batch,
    st_connected,
    st_token_id
  } from "./stores";

  // connection
  let connected = true;
  st_connected.subscribe((value) => {
    connected = value;
  });

  // user management
  let auth;
  let email;
  let userRegistered = false;
  let instructions_loaded = false;
  st_auth.subscribe((value) => {
    auth = value;
  });
  st_email.subscribe((value) => {
    email = value;
  });
  st_userRegistered.subscribe((value) => {
    userRegistered = value;
    // // console.log('updated user reegistration in check')
  });
  st_instructions.subscribe((value) => {
    instructions_loaded = value && Object.keys(value).length > 1;
  });

  let tokenId;
  st_token_id.subscribe((value)=>{
    tokenId  = value;
    // console.log("token value we got , ",value);
  })

  // geolocation
  let location_latitude;
  let location_longitude;
  let location_accuracy;
  st_location_latitude.subscribe((value) => {
    location_latitude = value;
  });
  st_location_longitude.subscribe((value) => {
    location_longitude = value;
  });
  st_location_accuracy.subscribe((value) => {
    location_accuracy = value;
  });

  // product codes
  let ean;
  let batch;
  st_ean.subscribe((value) => {
    ean = value;
  });
  st_batch.subscribe((value) => {
    batch = value;
  });

  let showScanEANClicked = false;
  // =========================== Input Product Codes ===========================

  let batch_valid = false;
  let ean_valid = false;

  const validateBatch = () => {
    showBadlist = false;
    if (!batch) {
      batch = "";
      batch_valid = false;
    } else {
      let str = batch.toUpperCase(); //.substring(0, 12);

      str = str.replace(/\s/gi, "_"); // Whitespaces to undersores
      str = str.replace(/_+/gi, "_"); // only one underscore at a time
      str = str.replace(/([^A-Z0-9:_]+)/gi, "");

      batch_valid = str.length >= 2;
      batch = str;
    }

    st_batch.set(batch);

    filterBadList();
  }; //validateBatch()

  const validateEAN = () => {
    // // console.log(`EAN:  -> ${ean}`);
    showBadlist = false;
    if (!ean) {
      ean_valid = false;
      return;
    }

    let str = ean;
    str = str.replace(/([^0-9]+)/gi, "");
    // str = str.substring(0, 8);
    ean_valid = str.length >= 8;
    
    ean = str;

    if (ean_valid) {
      st_ean.set(ean);
    }
    
    filterBadList();
  }; //validateEAN()

  let Barcode1D = [
    "CODABAR",
    "CODE_39",
    "CODE_93",
    "CODE_128",
    "EAN_8",
    "EAN_13",
    "ITF",
    "MAXICODE",
    "RSS_14",
    "RSS_EXPANDED",
    "UPC_A",
    "UPC_E",
    "UPC_EAN_EXTENSION",
    "MSI_PLESSEY"
  ]
  const reportNewFake = () => {
    if (!ean_valid) {
      return;
    }

    close(PAGE.reportNewFake);
  }; //reportNewFake()

  // ================================= ScanEAN =================================

  let showScanEAN = false;

  export const closeScanEAN = (evt) => {
    // // console.log("-----in close Scan ean--------",evt);
    showScanEAN = false;

    if (evt.detail.ean) {
      ean = evt.detail.ean;
      validateEAN();
      searchBadlist();
    }
  }; //closeScanEAN()

  // ============================= Badlist matches =============================

  let badList = [];
  let badListFiltered = [];
  let showBadlist = false;
  let imagesPrefixData = [];
  const searchBadlist = async () => {
    // dispatch('message', {text: "Loading....."});
    showLoader = true;
    showBadlist = false;
    if (!(connected && batch_valid | ean_valid)) {
      showLoader = false;
      showBadlist = true;
      return;
    }

    let reqJson = {
      ...auth,
      batch: batch ? await toEncrypt(batch) : null,
      ean: ean ? await toEncrypt(parseInt(ean, 10)) : null,
      latitude: await toEncrypt(location_latitude),
      longitude: await toEncrypt(location_longitude),
    };

    const reply = await fetch(URI_API("search_badlist"), {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reqJson),
    });


    try {
      const compressData = await reply.arrayBuffer();
      const decompressData = pako.inflate(new Uint8Array(compressData), {
        to: "string",
      });

      badList = JSON.parse(decompressData);
    } catch (error) {
      // console.log("in catch: ", error);
      showLoader = false;
    }

    if (reply.ok) {
      // badList = await reply.json();
      showLoader = false;
      badListFiltered = badList;
      if (Object.keys(badListFiltered).length) {
        for (let i = 0; i < badListFiltered.length; i++) {
          badListFiltered[i].batch = await toDecrypt(badListFiltered[i].batch);
          badListFiltered[i].comment = await toDecrypt(
            badListFiltered[i].comment
          );
          badListFiltered[i].ean = parseInt(
            await toDecrypt(badListFiltered[i].ean)
          );
          badListFiltered[i].id = parseInt(
            await toDecrypt(badListFiltered[i].id)
          );
          badListFiltered[i].images = await toDecrypt(
            badListFiltered[i].images
          );

          imagesPrefixData[i] = badListFiltered[i].img_prefix.split("|");
        }
      }
      // // console.log("badList: ",badList);
      showBadlist = true;
    } else {
      showLoader = false;
      if (reply.status >= 500) {
        st_connected.set(false);
        dispatch("message", {
          text: $t("page_check.textOffline"),
        });
      } else {
        const msg = await reply.text();
        if (msg.includes("Unauthorized")) {
          dispatch("message", {
            text: $t("page_check.textUnauthorized"),
          });
        } else {
          dispatch("message", { text: $t("page_check.dbFailed")});
        }
      }
    }
  }; //searchBadlist()
  const filterBadList = () => {
    if (!showBadlist | !badList | (badList.length < 1)) {
      return;
    }

    //Filter list by batch code
    if (batch && batch.length > 0) {
      badListFiltered = badList.filter((item) => {
        return item.batch.includes(batch);
      });
    } else {
      // // console.log('reset filter');
      badListFiltered = badList;
    }

    //Filter list by EAN
    if (ean && ean.length > 0 && badListFiltered.length > 0) {
      badListFiltered = badListFiltered.filter((item) => {
        return item.ean.toString().includes(ean);
      });
    }
  }; //filterBadList()

  let activeMatch;
  let showBadlistMatch = false;

  const openBadlistMatch = (match) => {
    // // console.log("match: ",match);
    activeMatch = match;
    showBadlistMatch = true;
  }; //openBadlistMatch()

  const closeMatchModal = (evt) => {
    if (evt.detail.answer && evt.detail.answer == "confirmed") {
      dispatch("message", {
        text: $t("page_check.thanksMessage"),
      });
      resetSearch();
    }
    showBadlistMatch = false;
  }; //closeMatchModal()

  const resetSearch = () => {
    st_ean.set(undefined);
    st_batch.set("");
    ean = undefined;
    batch = "";
    ean_valid = false;
    batch_valid = false;

    badList = [];
    badListFiltered = [];

    showBadlist = false;
    showBadlistMatch = false;
  }; //resetSearch();

  // const compressBlobImages = async () => {
  //   const apiResponse = await fetch(URI_API("compressBlobImages"), {
  //     method: "post",
  //     headers: { "Content-Type": "application/json" },
  //     // body: JSON.stringify(reqJson)
  //   });

  //   // console.log("apiResponse: ", apiResponse);
  // };
  // ===========================Multilingual support ==========================
  import {t} from "./i18n"

  //Scanbot needs to be relaunched after language change
  const reOpenScanEAN = (event) =>{
    if(showScanEAN)
    { 
      showScanEAN = false
      setTimeout(()=>{
        showScanEAN = true;
      },10)
    }
  }
  document.addEventListener('langChanged', reOpenScanEAN);
  // =========================== Construction / Init ===========================

  import { onMount, onDestroy } from "svelte";
  document.addEventListener('click', function(event) {
    const target = event.target;
    if(showScanEANClicked== true && target.id != "btn-scanEAN"){
    const container = document.getElementById('scan-upload-option'); // Replace with your container ID

    // Check if the clicked element is outside the container
    // console.log("clicked1: ",container);
    // console.log("clicked: ",target);
    if (!container.contains(target)) {
        // Hide the container
       
            showScanEANClicked= false;
    }
    }
   
});

  onMount(async () => {
    // compressBlobImages();
    await resetSearch();

    let scanbotInitialize = await initializeScanbot();
    if (scanbotInitialize.initialized) {
      scanbotInitializationDone = true;
    }
  }); //onMount()

  function close(nextPage = 0) {
    dispatch("close", { page: nextPage });
  } //close()

  onDestroy(async () => {
    scanbotInitializationDone = false;
  }); //onDestroy()

  function openLink(value) {
    let language = localStorage.getItem('userLang');
    let lang = localStorage.getItem('lang');
    if (value == "Privacy") {
      if(language){
        switch (language) {
          case 'es':
            window.open(`https://privacypolicy.pg.com/es-ES`, "_blank");
            break;
          case 'hi':
            window.open(`https://privacypolicy.pg.com/hi-IN`, "_blank");
            break;
          case 'pt':
            window.open(`https://privacypolicy.pg.com/pt-PT`, "_blank");
            break;
          case 'th':
            window.open(`https://privacypolicy.pg.com/th-TH`, "_blank");
            break;
          case 'vi':
            window.open(`https://privacypolicy.pg.com/vi-VN`, "_blank");
            break;
          default:
            window.open(`https://privacypolicy.pg.com/en-US/`, "_blank");
            break;
        }
      }
      else{
        switch (lang) {
          case 'es':
            window.open(`https://privacypolicy.pg.com/es-ES`, "_blank");
            break;
          case 'hi':
            window.open(`https://privacypolicy.pg.com/hi-IN`, "_blank");
            break;
          case 'pt':
            window.open(`https://privacypolicy.pg.com/pt-PT`, "_blank");
            break;
          case 'th':
            window.open(`https://privacypolicy.pg.com/th-TH`, "_blank");
            break;
          case 'vi':
            window.open(`https://privacypolicy.pg.com/vi-VN`, "_blank");
            break;
          default:
            window.open(`https://privacypolicy.pg.com/en-US/`, "_blank");
            break;
        }
      }
    }
    else{
      if(language){
        switch (language) {
          case 'es':
            window.open(`https://termsandconditions.pg.com/es-es/`, "_blank");
            break;
          case 'hi':
            window.open(`https://termsandconditions.pg.com/hi-in/`, "_blank");
            break;
          case 'pt':
            window.open(`https://termsandconditions.pg.com/pt-pt/`, "_blank");
            break;
          case 'th':
            window.open(`https://termsandconditions.pg.com/th-th/`, "_blank");
            break;
          case 'vi':
            window.open(`https://termsandconditions.pg.com/vi-vn/`, "_blank");
            break;
          default:
            window.open(`https://termsandconditions.pg.com/en-us/`, "_blank");
            break;
        }
      }
      else{
        switch (lang) {
          case 'es':
            window.open(`https://termsandconditions.pg.com/es-es/`, "_blank");
            break;
          case 'hi':
            window.open(`https://termsandconditions.pg.com/hi-in/`, "_blank");
            break;
          case 'pt':
            window.open(`https://termsandconditions.pg.com/pt-pt/`, "_blank");
            break;
          case 'th':
            window.open(`https://termsandconditions.pg.com/th-th/`, "_blank");
            break;
          case 'vi':
            window.open(`https://termsandconditions.pg.com/vi-vn/`, "_blank");
            break;
          default:
            window.open(`https://termsandconditions.pg.com/en-us/`, "_blank");
            break;
        }
      }
    }
  }

  let showLogo = true;
  const toggleTheme = () => {
    // console.log("Toggle theme: ", theme);
    theme.set(!theme)
    if(theme) showLogo = false;
    else showLogo = true;
  };

  function enablescannner(){
    showScanEAN = true;
    showScanEANClicked=false;
  }
  function enableuploadbarcode()
  {
    showScanEANClicked=false;
   let fileInput = document.querySelector('.file-picker');

   fileInput.click();

   fileInput.onchange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    // Be sure to read it as a data url. ScanbotSDK expects a base64 string
    reader.readAsDataURL(file);

    reader.onload = async (e) => {
        const result = await scanbotSDK.detectBarcodes(reader.result);
        // console.log("barcode detection result", result);
        result.barcodes.forEach(element => {
          if(Barcode1D.includes(element.format))
          {
            // console.log("Data to be shown ",element.text);
            ean = element.text;
            ean_valid=true;
            validateEAN();
          }
        });
    };
};
  }
  
</script>

<!-- =============================  H T M L  ============================= -->

<div class="page" class:light={!theme} class:dark={theme}>
  
 
  <div id="cont-search">
    <p>{$t("page_check.checkGenuine")}</p>
    <div class="input-txt" class:textBlue={!theme} class:dark={theme}>
      <div class="cont-row">
        <I_barcode size="40px" />
        <label for="txt-ean">{$t("page_check.barCode")}</label>
      </div>
      <div class="cont-row">
        <input
          id="txt-ean"
          class='ean-input'
          bind:value={ean}
          on:input={validateEAN}
          type="text"
          class:lightInput={!theme} class:dark={theme}
          placeholder= {$t("page_check.gtinBarcode")}
        />

        <button
          id="btn-scanEAN"
          on:click={() => (showScanEANClicked = true)}
          title={$t("page_check.scanBtn")}
          disabled={!scanbotInitializationDone}
          class:enabled={scanbotInitializationDone}
          class:disabled={!scanbotInitializationDone}
        >
        {$t("page_check.scanBtn")}
        </button>
      </div>
    </div>

    <div class="input-txt" class:textBlue={!theme} class:dark={theme}>
      <div class="cont-row">
        <I_batch size="40px" />
        <label for="txt-batch">{$t("page_check.batchCode")}</label>
      </div>
      <div class="cont-row">
        <input
          id="txt-batch"
          bind:value={batch}
          on:input={validateBatch}
          type="text"
          class:lightInput={!theme} class:dark={theme}
          placeholder={$t("page_check.batchCode")}
        />
      </div>
      <div class="input-info">
       {$t("page_check.leaveBlank")}
      </div>
    </div>

    <div class="cont-row" id="start-buttons">
      <!-- <div id="btn-resetSearch" on:click={resetSearch}>
            <DeleteIcon size='2x'/>
        </div> -->
      <button class="btn-resetSearch" class:textBlue={!theme} class:dark={theme} on:click={resetSearch} title="Back">
        <DeleteIcon size="2x" />
      </button>
      {#if userRegistered && instructions_loaded}
        <button
          on:click={reportNewFake}
          class="btn btn-gray"
          class:btn-blue={ean_valid}
          title="New Suspect"
          style="height: auto; min-height:54.5px"
        >
          {$t("page_check.newSuspect")}
        </button>
      {/if}
      <button
        on:click={searchBadlist}
        class="btn btn-gray"
        class:btn-blue={connected && (ean_valid || batch_valid)}
        title="Search List"
        style="height: auto; min-height:54.5px"
      >
        {$t("page_check.searchList")}
      </button>
    </div>

    {#if !connected}
      <p>{$t("page_check.offline")}</p>
    {/if}

    {#if showBadlist}
      {#if badListFiltered.length < 1}
        <h3>{$t("page_check.noEntries")}</h3>
      {:else}
        <div id="box-badlist">
          {#if badListFiltered.length == 1}
          <h2>{$t("page_check.fake1")}</h2>
          <p>{$t("page_check.handMatch")}</p>
          {:else}
            <h2>{badListFiltered.length} {$t("page_check.knownFake")}</h2>
            <p>
              {$t("page_check.imageMatch")}
            </p>
          {/if}
          <p>
            {$t("page_check.clickItem")}
          </p>

          {#each badListFiltered as match, index}
            <div
              class="box-badlistMatch"
            >
              <div class="box-bl-img">
                <!-- {#if match.images.includes('batch')}
                                <img src={URI_IMG(match.img_prefix + 'batch_thumb.jpg')} alt="" >
                            {:else} -->
                <!-- {#each imagesPrefixData[index][2] as img} -->
                <img src={imagesPrefixData[index][2]} alt={$t("page_check.productImage")} />
                <!-- {/each} -->
                <!-- {/if} -->
              </div>

              <div class="box-bl-text">
                <div class="bl-text-line">
                  <div class="bl-text-label">{$t("page_check.batch")}</div>
                  {match.batch}
                </div>

                <div class="bl-text-line">
                  <div class="bl-text-label">GTIN:</div>
                  {match.ean}
                </div>

                <p class="bl-comment">{match.comment}</p>

                <button class="btn" title="See More" class:light={!theme} class:dark={theme} on:click={() => openBadlistMatch(match)}>{$t("page_check.seeMore")}</button>
              </div>
            </div>
          {/each}
        </div>
      {/if}
    {/if}

    {#if showLoader}
      <div class="loader" />
    {/if}
  </div>
</div>

<footer class="footer" class:footDark={theme} >
  <!-- <div class="footer-link" on:click={()=> close(PAGE.help)}>
         About &#124; &#8203;
    </div>
    <div class="footer-link" on:click={()=> close(PAGE.dataPolicy)}>
        Data Policy &#124; &#8203;
    </div>
    <div class="footer-link" on:click={()=> openLink('Privacy')}>
        Privacy &#124; &#8203;
    </div>
    
    <div class="footer-link" on:click={()=> openLink('termsAndCondition')}>
        Terms And Conditions
    </div> -->
  <button class="btn-footer"  class:footDark={theme} on:click={() => close(PAGE.help)} title="About">
    {$t("page_check.about")} &#124; &#8203;
  </button>

  <button
    class="btn-footer"
    class:footDark={theme}
    on:click={() => close(PAGE.dataPolicy)}
    title="Data Policy"
  >
    {$t("page_check.dataPolicy")} &#124; &#8203;
  </button>

  <button
    class="btn-footer"
    class:footDark={theme}
    on:click={() => openLink("Privacy")}
    title="Privacy"
  >
    {$t("page_check.privacy")} &#124; &#8203;
  </button>

  <button
    class="btn-footer"
    class:footDark={theme}
    on:click={() => openLink("termsAndCondition")}
    title="Terms and Conditions"
  >
    {$t("page_check.tnc")}
  </button>
</footer>
<div style="margin:2px 2px 2px 2px" class:light={!theme} class:footDark={theme}>
  <div />
  <p style="font-size: 0.8em">
    © 2023 Procter & Gamble<br />
    {$t("page_check.allrights")}
  </p>
</div>

{#if showScanEANClicked}
<div class="scan-upload-option" id="scan-upload-option" style="position: absolute;width: -webkit-fill-available;bottom:0vh" class:lightDD={!theme} class:darkDD={theme}>
    <div class="upload-option" style="display: flex;justify-content: flex-start;padding-top:20px"on:click={enableuploadbarcode} >
      <span style="width:20%;margin-left:0;" class:textBlue={!theme}><UploadIcon size='1x'/></span>
      <span style="font-weight: bold;">Upload a barcode</span>
      <input class="file-picker" type="file" accept="image/jpeg" width="48" height="48">
    </div>
    <div class="scan-option" style="display: flex;justify-content: flex-start;padding:20px 0 20px 0"on:click={enablescannner} >
      <span style="width:20%;" class:textBlue={!theme}><I_barcode size='20px'/></span>
      <span style="font-weight: bold;">Scan a barcode</span>
    </div>
</div>
{/if}

{#if showScanEAN}
  <M_scanEAN on:close={closeScanEAN}  bind:theme={theme}/>
{/if}

{#if showBadlistMatch}
  <M_badlistMatch
    badlistMatch={activeMatch}
    on:close={closeMatchModal}
    on:retryGeo={() => dispatch("retryGeo")}
    bind:theme={theme}
  />
{/if}

<!-- ============================  S T Y L E  ============================ -->

<style>
  .loader {
    display: flex;
    position: relative;
    top: 30%;
    left: 45%;
    transform: translate(-50%, -50%);
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid var(--PG-blue);
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1.5s linear infinite; /* Safari */
    animation: spin 1.5s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .input-txt {
    margin-top: 2em;
    width: 100%;
    text-align: left;
    /* color: var(--PG-blue); */
  }
  .input-txt label {
    margin-left: 10px;
    line-height: 30px;
  }
  .input-txt input {
    line-height: 1.4em;
    width: 100%;
    font-size: 1.4em;
    padding: 0.2em 0.6em 0.2em 0.6em;
    border-radius: 50vh;
    /* border: 1px solid var(--PG-blue); */
  }
  .textBlue input{
    border: 1px solid var(--PG-blue);
  }
  .dark input{
    border: 1px solid #ffffff;
  }
  .ean-input {
    border-radius: 50vh 0px 0px 50vh !important;
    flex: 2;
  }
  #btn-scanEAN {
    color: white;
    font-size: 1em;
    background-color: var(--PG-blue);
    width: 25%;
    min-width: 90px;
    border-radius: 0px 50vh 50vh 0px;
    height: 2.65em;
    margin-left: -1px;
    border: none;
  }

  #btn-scanEAN.disabled {
    background-color: #747272;
  }

  #btn-scanEAN.enabled {
    background-color: var(--PG-blue);
  }

  .input-info {
    margin-top: 0.4em;
    font-size: 0.9em;
    color: #6d6969;
  }

  #start-buttons {
    margin-top: 3em;
    margin-bottom: 1em;
  }

  .btn-resetSearch {
    /* color: var(--PG-blue); */
    margin-right: 0.5em;
    border: none;
  }

  #box-badlist h2 {
    margin-top: 2em;
    font-size: 1.3em;
  }

  .box-badlistMatch {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
  }

  .box-bl-img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 5.7em;
    width: 5.7em;
    height: 5.7em;
    padding: none;
    border: 1px solid var(--txt-gray);
    margin-right: 1em;
    overflow: hidden;
  }

  .box-bl-img img {
    width: 105%;
    object-fit: contain;
    margin: -1px;
  }
  .box-bl-text {
    flex: 3;
    overflow-x: auto;
  }
  .bl-text-line {
    display: flex;
    flex-direction: row;
  }
  .bl-comment {
    margin-top: 0.5em;
    font-size: 0.9em;
    font-style: italic;
  }
  .box-bl-text .btn {
    font-size: 0.7em;
    float: right;
    margin: 0.5em 0px 0.5em 0px;
    padding: 0.2em 0.5em;
  }
  .bl-text-label {
    flex: 0 0 4em;
    color: var(--PG-blue);
    font-size: 0.8em;
  }

  .footer {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin-top: 3em; */
    font-size: 0.8em;
    color: #6d6969;
    border-top: 1px solid #bbb;
    padding: 0.5em;
    flex-wrap: wrap;
  }
  .btn-footer {
    color: var(--PG-blue);
    /* margin-right: 0.5em; */
    border: none;
    background: none;
  }
  .loading-text {
    font-size: 18px;
  }
  #btn-camera {
    color: white;
    border: none;
    background: none;
    /* margin-right: 8%;
    float: right; */
  }
  .light{
    background-color:  var(--bg-gray);
    color: #242424;
  }
  .dark{
    color: #ffffff;
    background-color: #242424;
  }

  .resetDark{
    color: var(--PG-blue);
    background-color: #242424;
  }
  .footDark{
    color: #fff !important;
    background-color: #000000;
  }
  .lightInput{
    background-color: white;
    color: #242424;
  }
  .textBlue{
    color: var(--PG-blue);
  }
  .lightDD {
    color: #272121;
    background-color: #fff;
  }
  .darkDD {
    color: #fff;
    background-color: #888383;
  }
</style>
