import { derived, writable } from "svelte/store";
import en from './locales/en'
import hi from './locales/hi'
import es from "./locales/es";
import pt from "./locales/pt";
import th from "./locales/th";
import vi from "./locales/vi";
const translations = {en,hi,es,pt,th,vi}
let defaultLang = navigator.language.split("-")[0];
export const locales = Object.keys(translations);
let finalLang;
if(!locales.includes(defaultLang))
{
  defaultLang = "en";
}
localStorage.setItem("lang", defaultLang)
 
if(localStorage.getItem("userLang")){
  finalLang = localStorage.getItem("userLang");
}else{
  finalLang = localStorage.getItem("lang");
}
export const locale = writable(finalLang)

function translate(locale, key, vars) {
  // // console.log('in i18n', locale, key )
  let page = key.split('.')[0];
  let variable = key.split('.')[1];
  // Let's throw some errors if we're trying to use keys/locales that don't exist.
  // We could improve this by using Typescript and/or fallback values.
  if (!key) throw new Error("no key provided to $t()");
  if (!locale) throw new Error(`no translation for key "${key}"`);
  // // console.log("..", typeof(key))
  // Grab the translation from the translations object.

  // // console.log(`Key is: ${key} and translation is for ${locale}.${key}`);
  let text = translations[locale][page][variable];
  // // console.log("text : ", text);
  
  if (!text) throw new Error(`no translation found for ${locale}.${key}`);

  // Replace any passed in variables in the translation string.
  Object.keys(vars).map((k) => {
    const regex = new RegExp(`{{${k}}}`, "g");
    text = text.replace(regex, vars[k]);
  });

  return text;
}

export const t = derived(locale, ($locale) => (key, vars = {}) =>
  translate($locale, key, vars)
);
