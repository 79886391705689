export default {
    app: {
      selectLang: "Chọn ngôn ngữ",
      messageText1:
        "Vui lòng cho chúng tôi biết bạn đã nhìn thấy sản phẩm giả ở đâu bằng cách cấp quyền 'Truy cập vị trí'.",
      messageText2:
        "Ứng dụng này sẽ hoạt động trong trình duyệt của bạn,\nyet để có trải nghiệm tốt nhất, chúng tôi khuyên bạn nên sử dụng Chrome hoặc Safari",
      messageText3:
        "Rất tiếc, trình duyệt của bạn cấm truy cập bộ nhớ. Vui lòng xem trang trợ giúp.",
    },
    page_check: {
      textOffline:
        "Bạn hiện ngoại tuyến.\nBạn vẫn có thể gửi nghi phạm mới để tải lên sau.",
      textUnauthorized:
        "Rất tiếc, tìm kiếm cơ sở dữ liệu không thành công.\n\n Vui lòng kiểm tra xem đăng ký của bạn có hợp lệ không.",
      dbFailed: "Rất tiếc, tìm kiếm cơ sở dữ liệu không thành công.",
      thanksMessage: "Cảm ơn bạn đã báo cáo sản phẩm giả mạo này",
      checkGenuine: "Kiểm tra xem sản phẩm của bạn có phải là P&G chính hãng hay không:",
      barCode: "Mã vạch",
      gtinBarcode: "mã vạch GTIN",
      scanBtn: "Quét",
      batchCode: "Mã lô",
      leaveBlank: "Để trống nếu không có mã lô trên sản phẩm",
      newSuspect: "Nghi phạm mới",
      searchList: "Danh sách tìm kiếm",
      offline: "Bạn hiện đang ngoại tuyến.",
      noEntries :"Không có mục nào của sản phẩm giả được tìm thấy.",
      fake1: "1 sản phẩm giả được biết đến:",
      handMatch: "Điều này có phù hợp với sản phẩm trên tay bạn không?",    
      knownFake: "sản phẩm giả được biết đến:",
      imageMatch:"Hình ảnh của bất kỳ sản phẩm nào trong số này có khớp với sản phẩm trên tay bạn không?",
      clickItem: "Bấm vào mục để biết chi tiết. Hãy đặc biệt chú ý đến mã lô.",
      productImage: "Hình ảnh sản phẩm",
      batch: "Lô hàng",
      seeMore: "Xem thêm",
      about: "Về",
      dataPolicy: "Chính sách dữ liệu",
      privacy: "Thông báo bảo mật",
      tnc: "Điều khoản & điều lệ ",
      allrights:"Đã đăng ký Bản quyền",
    },
    page_reportNewFake: {
      additionalImage: "Hình ảnh bổ sung. Vui lòng thêm bình luận bên dưới để giải thích.",
      fileNotSupported:"Loại tệp không được hỗ trợ",
      uploadMsg: "Đang tải lên ở chế độ nền;\ncó thể mất vài giây.",
      close: "Đóng",
      submitPhotos:"Gửi ảnh của",
      suspectProduct: "sản phẩm nghi ngờ",
      required: "Yêu cầu",
      additionalImage1: "Hình ảnh bổ sung",
      additionalImage2: "Thêm một hình ảnh bổ sung nếu bạn nghĩ điều này sẽ hữu ích.",
      comment: "Bình luận",
      additionalImagePlaceholder:"Nhận xét và/hoặc quan sát bổ sung.",
      checkBatchcode: "Mã lô đúng không?",
      batchCode: "Mã lô",
      whereDidYouSee: "Bạn thấy nó ở đâu?",
      useCurrentLocation:"Sử dụng vị trí hiện tại của tôi",
      differentLocation:"Chọn vị trí khác",
      processing: "..xử lý..",
      submit:"Gửi bằng địa chỉ trên",
      titleReceipt: "Biên lai",
      instrReceipt: "Không bắt buộc. Thêm ảnh của biên lai bán hàng.",
      titleGTIN: "Mã vạch",
      instrGTIN: "Vui lòng chụp ảnh mã vạch sản phẩm (GTIN).",
      titleBatch: "Mã lô",
      instrBatch: "Vui lòng chụp ảnh của mã lô.",
      titleBackLabel: "Nhãn sau",
      instrBackLabel: "Vui lòng chụp ảnh của nhãn sau đầy đủ của sản phẩm."
    },
    page_locations:{
      permissionIDB: "Rất tiếc, ứng dụng này không thể truy cập bộ nhớ trình duyệt trên thiết bị của bạn để lưu vị trí cho bạn.",
      browserPermission: "Vui lòng kiểm tra cài đặt quyền của trình duyệt của bạn và cho phép `dữ liệu trang web` trong phần `Quyền riêng tư & Bảo mật`.",
      addLocation: "Thêm vị trí",
      locationName: "Tên địa phương:",
      nameTaken: "tên đã được sử dụng",
      placeName: "Tên địa điểm",
      search: "Tìm kiếm:",
      NameAndAdd: "Tên và địa chỉ ",
      locationImage: "Hình ảnh vị trí",
      address: "Địa chỉ:",
      coordinates: "Tọa độ:",
      here: "đây",
      save: "Cứu",
      storedLocations: "Vị trí được lưu trữ",
      searchSaved: "Tìm kiếm ở các vị trí đã lưu:",
      filterByName: "Lọc theo tên",
      deleteAllPlaces: "Xóa tất cả các địa điểm",
    },
    page_settings: {
      register:"Vui lòng nhập email để đăng ký.",
      inviteCode: "You need an invitation code to register.",
      registerFailed: "Rất tiếc, đăng ký không thành công.\n(Lỗi máy chủ)",
      registerSuccess: "Đăng ký của bạn đã thành công",
      registerFailed1: "Rất tiếc, đã xảy ra lỗi với đăng ký của bạn.",
      offline: "Bạn hiện đang ngoại tuyến.\nVui lòng thử lại sau.",
      registerFailed2: "Rất tiếc, đăng ký không thành công vì:\n",
      reinvite: "Lời mời lại yêu cầu một địa chỉ email hợp lệ.",
      invite: "Một email mời đã được gửi tới ",
      inviteFailed: "Đang gửi lời mời đến ",
      failed: " thất bại.",
      permissionIDB:"Rất tiếc, ứng dụng này không thể truy cập bộ nhớ trình duyệt trên thiết bị của bạn để lưu giữ thông tin đăng nhập của bạn.",
      yourAccount:"Tài khoản của bạn",
      siteDataPermission:"Vui lòng kiểm tra cài đặt quyền của trình duyệt của bạn và cho phép 'dữ liệu trang web' trong phần 'Quyền riêng tư & Bảo mật`.Please check your browser permission settings and allow 'site data' under `Privacy & Security`.",
      registeredName:"Bạn đã đăng ký là",
      registeredEmail: "với email",
      accountStatus: "Trạng thái tài khoản của bạn là",
      regExp: "Đăng ký hết hạn vào",
      deleteRegistration: "Xóa/đặt lại đăng ký",
      email: "E-mail",
      emailPlaceholder: "địa chỉ email của bạn",
      emailAndCode:"Nếu bạn đã được mời,\n vui lòng nhập email và mã\n trước khi nhấp vào 'Đăng ký'.",
      code: "Mã số",
      inviteCode:"Mã mời",
      register1: "Đăng ký",
      regCode: "Mã Đăng Ký",
      migCode: "Bạn có mã di chuyển không?"
    },
    page_about: {
        privacy: "Sự riêng tư",
        cookies: "Dịch vụ web này không sử dụng cookie.",
        warning:"Khi bạn gửi báo cáo về sản phẩm bị nghi ngờ là giả mạo, ứng dụng sẽ thu thập và gửi thông tin sau đến máy chủ:",
        details:"Chi tiết sản phẩm - GTIN, Hình ảnh",
        timeAndDate: "Ngày và giờ báo cáo",
        location:"Vị trí của báo cáo",
        email:"Địa chỉ email",
        browserInfo:"Thông tin trình duyệt",
        referLink:"Để biết thêm thông tin, hãy tham khảo",
        privacy: "Chính sách bảo mật",
      },
      page_help:{
        about: "Về",
        aboutDesc: "Ứng dụng dựa trên web chính hãng cho phép bạn tìm kiếm và xác minh xem sản phẩm P&G bị nghi ngờ là chính hãng hay giả mạo bằng cách quét hoặc nhập Mã số sản phẩm thương mại toàn cầu (GTIN) của sản phẩm đó.",
        aboutDesc1: "Với tư cách là người dùng đã đăng ký, ứng dụng cho phép bạn chụp và gửi ảnh của những sản phẩm bị nghi ngờ là hàng giả. Báo cáo về việc nhìn thấy có thể truy cập được ở cả chế độ trực tuyến và ngoại tuyến.",
        counterfeit: "Báo cáo về việc nhìn thấy hàng giả",
        counterfeitDesc: "Khi bạn tìm thấy một sản phẩm giả được xác định và cho phép báo cáo việc phát hiện đó, bạn sẽ được chuyển đến một hộp thoại nơi bạn có thể chia sẻ thông tin chi tiết với P&G.",
        counterfeitDesc1: "Việc cho chúng tôi biết nơi bạn nhìn thấy sản phẩm giả sẽ cho phép chúng tôi bảo vệ cả người tiêu dùng và thương hiệu của mình. Vui lòng để lại nhận xét trước khi báo cáo việc nhìn thấy.",
        support : "Sự hỗ trợ của bạn thực sự được đánh giá cao.",
        reportFake: "Báo cáo giả = nghi ngờ",
        welcomeMsg: "Bạn đã được P&#38;G đích thân mời sử dụng ứng dụng này không chỉ để so sánh sản phẩm với các sản phẩm giả đã biết mà còn để báo cáo những nghi phạm mới về sản phẩm giả.",
        instruction: "Vui lòng liên hệ P&38;G để được hướng dẫn chi tiết.",
      },
      page_tnc:{
        tnc: "Các điều khoản và điều kiện",
        linkDesc: "Việc sử dụng và truy cập thông tin trên trang này phải tuân theo",
        linkDesc1: ", được nêu trong thỏa thuận pháp lý của chúng tôi.",
      },
      page_dp:{
        dataPolicy: "Chính sách dữ liệu",
        cookies:"Dịch vụ web này không sử dụng cookie.",
        dpDesc:"Khi bạn gửi báo cáo về sản phẩm bị nghi ngờ là giả mạo, ứng dụng sẽ thu thập và gửi thông tin sau đến máy chủ:",
        productDetails: "Chi tiết sản phẩm - GTIN, Hình ảnh",
        tnd:"Ngày và giờ báo cáo",
        location: "Vị trí của báo cáo",
        email: "Địa chỉ email",
        browserInfo: "Thông tin trình duyệt",
      },
      minion:{
        offlineMsg: "Bạn hiện ngoại tuyến.\n\nẢnh của bạn sẽ được tải lên\nkhi bạn trực tuyến trở lại.",
        uploadMsg:"Cảm ơn bạn, tải lên thành công!",
        uploadMsg1:"Quá trình tải lên mất nhiều thời gian hơn dự kiến.\nVui lòng thử lại với kết nối tốt hơn.",
        uploadMsg2:"Rất tiếc, tải lên không thành công.",
        uploadMsg3:"Quá trình tải lên dữ liệu bị hủy\nvì mất nhiều thời gian hơn dự kiến.\nVui lòng thử lại với kết nối tốt hơn.",
        uploadMsg4:"Không thể tải lên tất cả dữ liệu,\nvui lòng thử lại khi trực tuyến.",
        errMsg: "Sự cố khi phân tích tệp hình ảnh.",
        errMsg1: "Loại tệp không được hỗ trợ.",
        errMsg2: "Không thể tìm thấy khi ngoại tuyến."
      },
      badlistMatch:{
        backLabel:"Nhãn sau",
        batchCode:"Mã lô",
        position:"Vị trí hiện tại",
        offlineMsg:"Bạn hiện ngoại tuyến.\nBạn vẫn có thể gửi nghi phạm mới để tải lên sau.",
        failureMsg: "Rất tiếc, việc báo cáo việc quan sát không thành công.",
        sightingConfirmed:"Đây có phải là sản phẩm? Nếu nó có mã lô, nó có giống hệt như trong hình trên không?",
        no: "KHÔNG",
        yes: "Đúng",
        whereDidYouSee: "Bạn thấy nó ở đâu?",
        currentLocation: "Sử dụng vị trí hiện tại của tôi",
        differentLocation: "Chọn vị trí khác",
        comment: "Bạn có muốn thêm một bình luận?",
        commentPlaceholder: "quan sát bổ sung...",
        processing: "..xử lý..",
        sendPg: " Gửi tới P&G",
      },
      locationSelect: {
        currentposition: "Vị trí hiện tại",
        picked:"Đã chọn trên bản đồ",
        useCurrentLocation: "Sử dụng vị trí hiện tại của tôi",
        useAddress:  "Sử dụng địa chỉ trên",
        savedLocation: "Chọn vị trí đã lưu:",
      },
      menu:{
        locations: "Địa điểm",
        resumeUpload:"Tiếp tục Tải lên",
        pauseUpload:"Tạm dừng tải lên",
        manageDevice: "Quản lý thiết bị",
        account: "Tài khoản",
        cache:"Xóa bộ nhớ đệm",
        quit: "Thoát ứng dụng",
      },
      scanEAN: {
        align:"Vui lòng căn chỉnh mã vạch ở khung trên để quét",
        camera: "Chọn máy ảnh",
        rescan:"Quét lại",
        ok:"Được rồi",
        scan:"Đang quét mã vạch ...",
      },
      store:{
        address: "452, Đường Basingstoke, Whitley, Reading, Anh, RG2 0JF, Vương quốc Anh",
      },
      manageDevices:{
        edit: "Chỉnh sửa",
        done: "Hoàn tất",
        manageD: "Quản lý thiết bị",
        thisDevice:"Thiết bị này",
        active: "Kết nối đang hoạt động",
        move: "Di chuyển sang thiết bị mới",
        unlink:"Thiết bị này sẽ tự động gỡ kết nối sau khi bạn đã ghép với một thiết bị mới",
        generateCode: "Tạo mã di chuyển"
      },
      page_reg:{
        "enterCode": "Nhập Mã Đăng Ký",
        "enterMig": "Nhập mã di chuyển",
        "regCode": "Mã Đăng Ký",
        "inviteCode": "Mã Mời",
        "register": "Đăng Ký",
        "enterMigGenerated": "Nhập mã di chuyển được tạo từ tài khoản hiện tại của bạn để chuyển đổi đăng ký.",
        "migCode": "Mã Di Chuyển"
      }
      
  };
  