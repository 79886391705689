export default {
  app: {
    selectLang: "Select Language",
    messageText1:
      "Please allow us to know where you saw the fake product by giving the 'Access Location' permission.",
    messageText2:
      "This app should work in your browser,\nyet for best experience we recommend to use Chrome or Safari",
    messageText3:
      "Sorry, your browser forbids storage access. Please see help page.",
  },
  page_check: {
    textOffline:
      "You are currently offline.\nYou may still submit new suspects for upload later.",
    textUnauthorized:
      "Sorry, database search failed.\n\n Please check your registration is valid.",
    dbFailed: "Sorry, database search failed.",
    thanksMessage: "Thank you for reporting this fake product",
    checkGenuine: "Check if your product is genuine P&G:",
    barCode: "Bar Code",
    gtinBarcode: "GTIN barcode",
    scanBtn: "Scan",
    batchCode: "Batch Code",
    leaveBlank: "Leave blank if no batch code is present on the product",
    newSuspect: "New Suspect",
    searchList: "Search list",
    offline: "You are currently offline.",
    noEntries :"There are no entries of fake products found.",
    fake1: "1 known fake product:",
    handMatch: "Does this match the product in your hand?",    
    knownFake: "known fake products:",
    imageMatch:"Do the images from any of these products match the product in your hand?",
    clickItem: "Click on item for details. Please pay particular attention to the batch code.",
    productImage: "Product Image",
    batch: "Batch",
    seeMore: "See more",
    about: "About",
    dataPolicy: "Data Policy",
    privacy: "Privacy",
    tnc: "Terms and Conditions",
    allrights:"All rights reserved",
  },
  page_reportNewFake: {
    additionalImage: "Additional image. Please add comment below to explain.",
    fileNotSupported:"File type not supported",
    uploadMsg: "Uploading in the background;\nmay take a few seconds.",
    close: "Close",
    submitPhotos:"Submit photos of",
    suspectProduct: "suspect product",
    required: "Required",
    additionalImage1: "Additional image",
    additionalImage2: "Add an additional image, if you think this will be helpful.",
    comment: "Comment",
    additionalImagePlaceholder:"Additional comments and/or observations.",
    checkBatchcode: "Batch code correct?",
    batchCode: "Batch code",
    whereDidYouSee: "Where did you see it?",
    useCurrentLocation:"Use my current location",
    differentLocation:"Choose different location",
    processing: "..processing..",
    submit:"Submit using the above address",
    titleReceipt: "Receipt",
    instrReceipt: "Optional. Add a photo of the sales receipt." ,
    titleGTIN: "Bar code",
    instrGTIN: "Please take a close-up photo of the product barcode (GTIN).",
    titleBatch: "Batch code",
    instrBatch: "Please take a photo of the batch code.",
    titleBackLabel: "Back label",
    instrBackLabel: "Please take a photo of the full product back label.",
  },
  page_locations:{
    permissionIDB: "Unfortunately, this app cannot access browser storage on your device to save locations for you.",
    browserPermission: "Please check your browser permission settings and allow `site data` under `Privacy & Security`.",
    addLocation: "Add location",
    locationName: "Location name:",
    nameTaken: "name already taken",
    placeName: "Name of place",
    search: "Search:",
    NameAndAdd: "Name and address",
    locationImage: "Location Image",
    address: "Address:",
    coordinates: "Coordinates:",
    here: "Here",
    save: "Save",
    storedLocations: "Stored locations",
    searchSaved: "Search in saved locations:",
    filterByName: "Filter by name",
    deleteAllPlaces: "Delete all places",
  },
  page_settings: {
    register:"Please enter email to register.",
    inviteCode: "You need an invitation code to register.",
    registerFailed: "Sorry, registration failed.\n(Server error)",
    registerSuccess: "Your registration was successful",
    registerFailed1: "Sorry, something went wrong with your registration.",
    offline: "You are currently offline.\nPlease try again later.",
    registerFailed2: "Sorry, registration failed because:\n",
    reinvite: "Reinvitation requires a valid email address.",
    invite: "An invitation email has been sent to ",
    inviteFailed: "Sending invitation to ",
    failed: " failed.",
    permissionIDB:"Unfortunately, this app cannot access browser storage on your device to keep your login details.",
    yourAccount:"Your account",
    siteDataPermission:"Please check your browser permission settings and allow 'site data' under `Privacy & Security`.",
    registeredName:"You are registered as",
    registeredEmail: "with the email",
    accountStatus: "Your account status is",
    regExp: "Registration expires on",
    deleteRegistration: "Delete / reset registration",
    email: "Email",
    emailPlaceholder: "Your email address",
    emailAndCode:"If you have been invited,\n please enter email and code\n before clicking 'Register'.",
    code: "Code",
    inviteCode:"Invitation code",
    register1: "Register",
    regCode: "Registration Code",
    migCode: "Do you have a migration code?"
  },
  page_about: {
    privacy: "Privacy",
    cookies: "This web service does not use cookies.",
    warning:"As you submit a report of a suspected counterfeit product, the app collects and send the following information to the server:",
    details:"Details of the product - GTIN, Images",
    timeAndDate: "Time and date of report",
    location:"Location of the report",
    email:"Email address",
    browserInfo:"Browser information",
    referLink:"For more information, refer to",
    privacy: "Privacy Policy",
  },
  page_help:{
      about: "About",
      aboutDesc: "Genuine Web-Based Application allows you to search and verify if a suspected P&G product is genuine or counterfeit by scanning or entering its Global Trade Item Number (GTIN).",
      aboutDesc1: "As a registered user, the app allows you to take and submit photos of suspected counterfeits. Reporting a sighting is made accessible for both online and offline mode.",
      counterfeit: "Report on Counterfeit Sightings",
      counterfeitDesc: "As you find an identified counterfeit product and permits to report the sighting, you are directed to a dialog where you can share a detailed information with P&G.",
      counterfeitDesc1: "Telling us where you saw the counterfeit product allows us to safeguard both our consumers and our brand. Please leave a comment before reporting a sighting.",
      support : "Your support is truly appreciated.",
      reportFake: "Report fake= suspect",
      welcomeMsg: "You have been personnaly invited by P&#38;G to use this app not only to compare products with known fake products, but also to report new suspects of fake producs.",
      instruction: "Please contact P&#38;G for detailed instructions.",
    },
    page_tnc:{
      tnc: "Terms and Conditions",
      linkDesc: "The use and access to the information on this site is subject to the",
      linkDesc1: ",set out in our legal agreement.",
    },
    page_dp:{
      dataPolicy: "Data Policy",
      cookies:"This web service does not use cookies.",
      dpDesc:"As you submit a report of a suspected counterfeit product, the app collects and send the following information to the server:",
      productDetails: "Details of the product - GTIN, Images",
      tnd:"Time and date of report",
      location: "Location of the report",
      email: "Email address",
      browserInfo: "Browser information",
    },
    minion:{
      offlineMsg: "You are currently offline.\n\nYour photos will be uploaded\nwhen you are online again.",
      uploadMsg:"Thank you, upload succeeded!",
      uploadMsg1:"Upload took much longer than expected.\nPlease try again with better connection.",
      uploadMsg2:"Sorry, upload failed.",
      uploadMsg3:"Data upload aborted,\nbecause it took much longer than expected.\nPlease try again with better connection.",
      uploadMsg4:"Could not upload all data,\nplease try again when online.",
      errMsg: "Problem parsing image file.",
      errMsg1: "File type not supported.",
      errMsg2: "Can't be found when offline."
    },
    badlistMatch:{
      backLabel:"Back label",
      batchCode:"Batch code",
      position:"Current position",
      offlineMsg:"You are currently offline.\nYou may still submit new suspects for upload later.",
      failureMsg: "Sorry, reporting the sighting failed.",
      sightingConfirmed:"Is this the product? If it has a batch code, does it looks exactly like in the image above?",
      no: "no",
      yes: "yes",
      whereDidYouSee: "Where did you see it?",
      currentLocation: "Use my current location",
      differentLocation: "Choose different location",
      comment: "Would you like to add a comment?",
      commentPlaceholder: "Additional observations...",
      processing: "..processing..",
      sendPg: " Send to P&G",
    },
    locationSelect: {
      currentposition: "Current position",
      picked:"Picked on map",
      useCurrentLocation: "Use my current location",
      useAddress:  "Use the above address",
      savedLocation: "Select a saved location:",
    },
    menu:{
      locations: "Locations",
      resumeUpload:"Resume upload",
      pauseUpload:"Pause upload",
      manageDevice: "Manage Devices",
      account: "Account",
      cache:"Clear cache",
      quit: "Quit App",
    },
    scanEAN: {
      align:"Please align the barcode in the frame above to scan it",
      camera: "Select Camera",
      rescan:"Rescan",
      ok:"OK",
      scan:"Scanning for barcode ...",
    },
    store:{
      address: "452, Basingstoke Road, Whitley, Reading, England, RG2 0JF, United Kingdom",
    },
    manageDevices:{
      edit: "Edit",
      done: "Done",
      manageD: "Manage Devices",
      thisDevice:"This Device",
      active: "Active Sessions",
      move: "Move to new Device",
      unlink:"This device will automatically unlink after you have paired to a new device",
      generateCode: "Generate Migration Code"
    },
    page_reg:{
      enterCode: "Enter Registration Code",
      regCode: "Registration Code",
      inviteCode: "Invitation Code",
      register: "Register",
      enterMigGenerated:"Enter the migration code generated from your existing account to transfer the registration.",
      migCode:"Migration Code",
      enterMig: "Enter Migration Code"
    }
};
